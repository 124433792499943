import { Checkbox, Col, Row, Tooltip } from "antd";
import { mockSSEEndDataMessage } from "../mockData";
import { EyeOutlined } from "@ant-design/icons";
import "./ModulesListing.css";
import { useEffect, useState } from "react";
import { ModulesPreviewModal } from "../ModulesPreview/ModulesPreviewModal";
import { ModuleType } from "../types";
import { useAppDispatch } from "../../../../../Store/hooks";
import { setCNCurrentIndexData, setCNCurrentIndexPartialData, setCNSelectedModuleIds } from "../../../../../Store/actions/_contentNewtonActions";
import { ListingModuleStepData } from "../ModuleSelection";

export const ModulesListing = ({
  modules,
  stepData,
  selectionLimit
}: {
  modules: ModuleType[];
  stepData: ListingModuleStepData;
  selectionLimit: number;
}) => {
  // const modulesList = mockSSEEndDataMessage.data as ModuleType[];
  const [modulesList, setModulesList] = useState<ModuleType[]>([]);
  const [selectedModuleIds, setSelectedModuleIds] = useState<number[]>(stepData?.selectedModuleIds ?? []);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState<ModuleType>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setModulesList(modules);
  }, [modules]);

  // const handleSelectDeselect = (id: number) => {
  //   if (selectedModuleIds.includes(id)) {
  //     setSelectedModuleIds(selectedModuleIds.filter((item) => item !== id));
  //     //Global state update
  //     dispatch(setCNSelectedModuleIds(selectedModuleIds.filter((item) => item !== id)));
  //   } else {
  //     setSelectedModuleIds([...selectedModuleIds, id]);
  //     //Global state update
  //     dispatch(setCNSelectedModuleIds([...selectedModuleIds, id]));
  //   }
  // };
  const handleSelectDeselect = (id: number) => {
    if (selectedModuleIds.includes(id)) {
      // Deselect the item
      const newSelectedModuleIds = selectedModuleIds.filter((item) => item !== id);
      setSelectedModuleIds(newSelectedModuleIds);

      //TODO: Cleanup required
      dispatch(setCNCurrentIndexPartialData({ selectedModuleIds: selectedModuleIds.filter((item) => item !== id) } as ListingModuleStepData));
      // Global state update
      dispatch(setCNSelectedModuleIds(newSelectedModuleIds));
    } else {
      // Selecting the item
      let newSelectedModuleIds;
      if (selectionLimit === 1) {
        // Only one item can be selected. Replace `selectedModuleIds` with the new id.
        newSelectedModuleIds = [id];
      } else {
        // Check if current selection count has reached the limit
        if (selectedModuleIds.length >= selectionLimit) {
          // Remove the most recently added item (last item in the array)
          newSelectedModuleIds = [...selectedModuleIds.slice(0, -1), id];
        } else {
          newSelectedModuleIds = [...selectedModuleIds, id];
        }
      }
      setSelectedModuleIds(newSelectedModuleIds);
      dispatch(setCNCurrentIndexPartialData({ selectedModuleIds: [...selectedModuleIds, id] } as ListingModuleStepData));
      // Global state update
      dispatch(setCNSelectedModuleIds(newSelectedModuleIds));
    }
  };

  // modulesList?.flatMap((item, index) =>
  //   Array.from({ length: 2 + 1 }).map((_, i) => ({
  //     ...item,
  //     id: `${item.module_id}-${i}`
  //   }))
  // );

  return (
    <Row style={{ display: "flex" }}>
      <Col span={24} /* style={{ display: "flex", flexDirection: "column" }} */>
        <Row
          gutter={8}
          style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto", overflowY: "hidden", width: "100%", flex: 1, padding: "5px" }}
        >
          {modulesList?.map((item) => (
            <Col span={6} key={item.id}>
              <div className="checkbox-tile content-container" onClick={() => handleSelectDeselect(item.id)}>
                <Checkbox
                  checked={selectedModuleIds.includes(item.id)}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => handleSelectDeselect(item.id)}
                  className="checkbox-overlay"
                />
                {/* <img src={item.thumbnail} className={`item ${isSelected(key, item.asset_id) ? "selected-item" : ""}`} /> */}
                <div style={{ /* height: "250px", */ height: "100%", padding: "40px", background: "#f0f7ff", color: "#5f5f5f", borderRadius: "9px" }}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Module ID: </span>
                    <span>{item.module_id}</span>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Primary Claim: </span>
                    <span>{item.claims.find((claim) => claim.type.toLowerCase() === "primary")?.text}</span>
                  </div>
                </div>
                <Tooltip title="Preview">
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      fontSize: "18px",
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      padding: "3px",
                      cursor: "pointer"
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPreviewItem(item as ModuleType);
                      setOpenPreview(true);
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          ))}
        </Row>
        {previewItem && openPreview && (
          <ModulesPreviewModal open={openPreview} setOpen={setOpenPreview} previeweditem={previewItem} modulesList={modulesList} />
        )}
      </Col>
    </Row>
  );
};
