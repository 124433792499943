import React, { useEffect, useState } from "react";
import { Modal } from "antd";
// import { TemplateTile } from "../TemplateTile";
import { FinalAssetTemplateType } from "../../Module Selection/types";
import CardWithOverlayCheckbox from "../../../components/common/CardsWithCheckbox/CardWithOverlayCheckbox/CardWithOverlayCheckbox";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { ContentNewtonState } from "../../../../../Store/reducers/_contentNewtonReducer";
import { useSelectablePreviewableCards } from "../../../components/common/CardsWithCheckbox/useSelectablePreviewableCards";
import { setCNSelectedTemplateId } from "../../../../../Store/actions/_contentNewtonActions";
// import "./TemplatesPreviewModal.css";

const scrollBarStyles = `
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 12px;
  }
</style>
`;

export const TemplatesPreviewModal: React.FC<{
  open: boolean;
  setOpen: Function;
  previewItem: FinalAssetTemplateType;
  templatesList: FinalAssetTemplateType[];
}> = ({ open, setOpen, previewItem, templatesList }) => {
  const { selectedTemplateId }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);
  const [htmlFileContent, setHtmlFileContent] = useState("");

  const { checkedItems, previewedItem, toggleCheckedItem, selectPreviewItem } = useSelectablePreviewableCards<FinalAssetTemplateType>({
    multiSelect: false,
    initialCheckedItems: selectedTemplateId ? [templatesList.find((item) => item.id === selectedTemplateId)!] : [],
    initialPreviewedItem: previewItem
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    checkedItems.length && dispatch(setCNSelectedTemplateId(checkedItems[0].id)); // Single select
  }, [checkedItems]);

  const oncancel = () => {
    setOpen(false);
  };
  const onOk = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!previewedItem) return;
    fetchHTMLContent(previewedItem.url).then((htmlContent) => {
      if (htmlContent) {
        setHtmlFileContent(scrollBarStyles + htmlContent);
      }
    });
  }, [previewedItem]);

  const onCheck = (item: FinalAssetTemplateType) => {
    toggleCheckedItem(item);
  };

  const imagesData = [
    "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-169994-674010.jpg&fm=jpg", // 400px width
    "https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg", // 300px width
    "https://miro.medium.com/v2/resize:fit:1024/1*w7fSHgKimriL-ryZJ52Uig.png", // 500px width
    "https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg", // 250px width
    "https://www.shutterstock.com/search/big-images" // 350px width
  ];

  async function fetchHTMLContent(url: string): Promise<string | null> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching HTML: ${response.statusText}`);
      }
      const text = await response.text();
      return text;
    } catch (error) {
      console.error("Error fetching HTML content:", error);
      return null;
    }
  }

  return (
    <Modal
      open={open}
      className="cn-fullscreen-variable-width-modal cn-modules-viewer"
      title={"Templates Preview"}
      onCancel={oncancel}
      onOk={onOk}
      onClose={oncancel}
      styles={{ body: { overflow: "hidden" } }}
    >
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ flex: 1, height: "calc(100vh - 160px)" /*  overflowY: "auto" */ }}>
          {/* <ModulePreview module={selectedItem} /> */}
          {/* IFrame for .html file */}
          {/* <iframe src={selectedItem.url} style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px", width: "100%", height: "100%" }} /> */}
          <iframe
            srcDoc={htmlFileContent}
            style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px", width: "100%", height: "100%" }}
          />
        </div>
        <div
          style={{
            height: "calc(100vh - 160px)",
            overflowY: "auto",
            width: "200px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            padding: "5px"
          }}
        >
          {templatesList?.map((item) => (
            // <TemplateTile item={item} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
            <CardWithOverlayCheckbox
              key={item.id}
              item={item}
              isSelected={checkedItems.some((checked) => checked.id === item.id)}
              isPreviewed={previewedItem?.id === item.id}
              onCheck={() => onCheck(item)}
              onClick={() => selectPreviewItem(item)}
              checkboxPosition="top-right"
              borderOn="previewed"
              opacityOn="previewed"
              content={<img src={item.thumbnail} style={{ width: "100%" }} />}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
