import { StepProps } from "antd";
import { ConversationStep, FinalAsset } from "../../Pages/Content Newton/resources/types";
import {
  CONVERSATION_STEPS,
  ConversationStatus,
  ConversationStepNametoStepNumberMapping,
  ConversationStepsMapping,
  StepperInitalState,
  StepperStepStatus,
  UserType
} from "../../Pages/Content Newton/resources/constants";
import { formatDateTime } from "../../Utils";
import {
  RESET_CN_STATES,
  SET_CN_AVAILABLE_MODULES_LIST,
  SET_CN_CONVERSATION_STEPS,
  SET_CN_CURRENT_INDEX,
  SET_CN_CURRENT_INDEX_DATA,
  SET_CN_CURRENT_INDEX_DONE,
  SET_CN_CURRENT_INDEX_DONE_NEXT_INDEX_PROCESS,
  SET_CN_CURRENT_INDEX_LOADING,
  SET_CN_CURRENT_INDEX_PARTIAL_DATA,
  SET_CN_CURRENT_INDEX_TIMESTAMP,
  SET_CN_CURRENT_STEP,
  SET_CN_CURRENT_STEP_DATA,
  SET_CN_CURRENT_STEP_DONE,
  SET_CN_CURRENT_STEP_DONE_GIVEN_STEP_PROCESS,
  SET_CN_CURRENT_STEP_DONE_NEXT_STEP_PROCESS,
  SET_CN_CURRENT_STEP_LOADING,
  SET_CN_CURRENT_STEP_TIMESTAMP,
  SET_CN_CURRENT_STEPPER_STEP_FINISH,
  SET_CN_CURRENT_STEPPER_STEP_FINISH_NEXT_STEP_PROCESS,
  SET_CN_DETAILS_INPUT_PROMPT,
  SET_CN_FINAL_ASSET,
  SET_CN_FINAL_ASSET_TEMPLATES_LIST,
  SET_CN_GIVEN_INDEX_DATA,
  SET_CN_LOCALISED_ASSET_DATA,
  SET_CN_MARKET_AND_LANGUAGE_INPUT_PROMPT,
  SET_CN_ORDER_ID,
  SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_INDEX,
  SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_STEP,
  SET_CN_PUT_STEPS_WITH_GIVEN_NAMES_AFTER_CURRENT_INDEX,
  SET_CN_SELECTED_MODULE_IDS,
  SET_CN_SELECTED_TEMPLATE_ID,
  SET_CN_STEPPER_CURRENT_STEP,
  SET_CN_STEPPER_STEPS,
  SET_CN_SYSTEM_SUGGESTED_MODULES_LIST,
  SET_CN_TRANSLATED_ASSET_DATA,
  SET_CN_TRANSLATION_LANGUAGE_INPUT_PROMPT
} from "../constants/_contentNewtonConstants";
import { FinalAssetTemplateType, ModuleType, SuggesteModuleSSEData } from "../../Pages/Content Newton/steps/Module Selection/types";
import { mockFinalAssetData, mockSSESystemSuggestedModulesAPIResponse } from "../../Pages/Content Newton/steps/Module Selection/mockData";
import { LocalisedAssetDataType } from "../../Pages/Content Newton/steps/Localisation/AssetLocalisation";

export interface ContentNewtonState {
  stepperCurrentStep: number;
  stepperSteps: StepProps[];
  conversationSteps: ConversationStep[];
  currentIndex: number;
  currentStep: number;
  currentStepLoading: boolean;
  detailsInputPrompt: string;
  availableModulesList: ModuleType[];
  selectedModuleIds: number[];
  orderId: number;
  // systemSuggestedModulesList: SuggesteModuleSSEData["data"]["system_suggested_modules"]; //ModuleType[];
  systemSuggestedModulesList: SuggesteModuleSSEData["data"];
  finalAssetTemplatesList: FinalAssetTemplateType[];
  selectedTemplateId: number;
  finalAsset: FinalAsset[] | [];
  marketAndLanguageInputPrompt: string;
  localisedAssetData: LocalisedAssetDataType | undefined;
  translationLanguageInputPrompt: string;
  translatedAssetData: LocalisedAssetDataType | undefined;
}

const initialState: ContentNewtonState = {
  stepperCurrentStep: 0,
  stepperSteps: StepperInitalState,
  conversationSteps: CONVERSATION_STEPS,
  currentIndex: 0,
  currentStep: 1,
  currentStepLoading: false,
  detailsInputPrompt: "",
  availableModulesList: [],
  selectedModuleIds: [],
  orderId: 0,
  systemSuggestedModulesList: { order_id: 0, modules: [], channel: "email", system_suggested_modules: [] },
  // //@ts-ignore
  // systemSuggestedModulesList: mockSSESystemSuggestedModulesAPIResponse.data,
  finalAssetTemplatesList: [],
  selectedTemplateId: 0,
  // finalAsset: mockFinalAssetData //;undefined
  finalAsset: [],
  marketAndLanguageInputPrompt: "",
  localisedAssetData: undefined,
  translationLanguageInputPrompt: "",
  translatedAssetData: undefined
};

export default function contentNewtonReducer(state: ContentNewtonState = initialState, action: any /* ContentNewtonActions */): ContentNewtonState {
  switch (action.type) {
    case SET_CN_STEPPER_CURRENT_STEP:
      return { ...state, stepperCurrentStep: action.payload };
    case SET_CN_STEPPER_STEPS:
      return { ...state, stepperSteps: action.payload };
    case SET_CN_CURRENT_STEPPER_STEP_FINISH:
      return {
        ...state,
        stepperSteps: state.stepperSteps.map((s, i) => (i === state.stepperCurrentStep ? { ...s, status: StepperStepStatus.FINISH } : s))
      };
    case SET_CN_CURRENT_STEPPER_STEP_FINISH_NEXT_STEP_PROCESS:
      return {
        ...state,
        stepperSteps: state.stepperSteps.map((s, i) =>
          i === state.stepperCurrentStep
            ? { ...s, status: StepperStepStatus.FINISH }
            : i === state.stepperCurrentStep + 1
              ? { ...s, status: StepperStepStatus.PROCESS }
              : s
        ),
        stepperCurrentStep: state.stepperCurrentStep + 1
      };
    case SET_CN_CONVERSATION_STEPS:
      return { ...state, conversationSteps: action.payload };
    case SET_CN_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    case SET_CN_CURRENT_STEP_DONE:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs) => (cs.step === state.currentStep ? { ...cs, status: ConversationStatus.DONE } : cs))
      };
    case SET_CN_CURRENT_STEP_DONE_NEXT_STEP_PROCESS:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs) =>
          cs.step === state.currentStep
            ? { ...cs, status: ConversationStatus.DONE }
            : cs.step === state.currentStep + 1
              ? { ...cs, status: ConversationStatus.PROCESS, timestamp: formatDateTime() }
              : cs
        ),
        currentStep: state.currentStep + 1
      };
    case SET_CN_CURRENT_STEP_DONE_GIVEN_STEP_PROCESS:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs) =>
          cs.step === state.currentStep
            ? { ...cs, status: ConversationStatus.DONE }
            : cs.step === action.payload
              ? { ...cs, status: ConversationStatus.PROCESS }
              : cs
        ),
        currentStep: action.payload
      };
    case SET_CN_CURRENT_STEP_DATA:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs) => (cs.step === state.currentStep ? { ...cs, data: action.payload } : cs))
      };
    case SET_CN_PUT_STEP_WITH_GIVEN_NAME_AFTER_CURRENT_STEP: {
      let stepWithGivenName: number = 0;
      Object.entries(ConversationStepsMapping).find(([key, value]) => {
        if (value.includes(action.payload)) {
          stepWithGivenName = Number(key);
        }
      });
      return {
        ...state,
        conversationSteps: [
          ...state.conversationSteps.slice(0, state.currentStep - 1),
          { ...state.conversationSteps[state.currentStep - 1], status: ConversationStatus.DONE }, //Mark currentStep Done
          {
            step: stepWithGivenName,
            stepName: action.payload,
            type: action.payload.startsWith("User") ? UserType.USER : UserType.ADMIN,
            status: ConversationStatus.PROCESS,
            timestamp: formatDateTime()
          }
        ]
      };
    }

    case SET_CN_CURRENT_STEP_LOADING:
      return { ...state, currentStepLoading: action.payload };
    case SET_CN_CURRENT_STEP_TIMESTAMP:
      const timestamp = action.payload ?? formatDateTime();
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs, i) => (i === state.currentStep - 1 ? { ...cs, timestamp } : cs))
      };

    case SET_CN_CURRENT_INDEX:
      return { ...state, currentIndex: action.payload };
    case SET_CN_CURRENT_INDEX_DONE:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs, index) =>
          index === state.currentIndex ? { ...cs, status: ConversationStatus.DONE } : cs
        )
      };
    case SET_CN_CURRENT_INDEX_DONE_NEXT_INDEX_PROCESS:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs, index) =>
          index === state.currentIndex
            ? { ...cs, status: ConversationStatus.DONE }
            : index === state.currentIndex + 1
              ? { ...cs, status: ConversationStatus.PROCESS, timestamp: formatDateTime() }
              : cs
        ),
        currentIndex: state.currentIndex + 1,
        currentStep: state.conversationSteps[state.currentIndex + 1].step
      };
    case SET_CN_CURRENT_INDEX_DATA:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs, index) => (index === state.currentIndex ? { ...cs, data: action.payload } : cs))
      };
    case SET_CN_CURRENT_INDEX_PARTIAL_DATA:
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs, index) =>
          index === state.currentIndex ? { ...cs, data: { ...cs.data, ...action.payload } } : cs
        )
      };
    case SET_CN_GIVEN_INDEX_DATA:
      let payload = action.payload as { index: number; data: { [key: string]: any } };
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs, index) =>
          index === payload.index ? { ...cs, data: { ...cs.data, ...payload.data } } : cs
        )
      };
    case SET_CN_PUT_STEPS_WITH_GIVEN_NAMES_AFTER_CURRENT_INDEX: {
      const newStepNames = action.payload as string[];

      const getStepDetails = (step: number, conversationSteps: ConversationStep[]) => {
        return conversationSteps.find((cs) => cs.step === step);
      };

      return {
        ...state,
        conversationSteps: [
          ...state.conversationSteps.slice(0, state.currentIndex + 1),
          ...(newStepNames.map((stepName) => ({
            // ...getStepDetails(ConversationStepNametoStepNumberMapping[stepName], state.conversationSteps),
            ...state.conversationSteps.find((cs) => cs.step === ConversationStepNametoStepNumberMapping[stepName]),
            status: ConversationStatus.WAIT,
            data: null
          })) as ConversationStep[]),
          ...state.conversationSteps.slice(state.currentIndex + 1)
        ]
      };
    }
    case SET_CN_CURRENT_INDEX_LOADING:
      return { ...state, currentStepLoading: action.payload };
    case SET_CN_CURRENT_INDEX_TIMESTAMP: {
      const timestamp = action.payload ?? formatDateTime();
      return {
        ...state,
        conversationSteps: state.conversationSteps.map((cs, i) => (i === state.currentIndex ? { ...cs, timestamp } : cs))
      };
    }

    case SET_CN_DETAILS_INPUT_PROMPT:
      return { ...state, detailsInputPrompt: action.payload };
    case SET_CN_AVAILABLE_MODULES_LIST:
      return { ...state, availableModulesList: action.payload };
    case SET_CN_SELECTED_MODULE_IDS:
      return { ...state, selectedModuleIds: action.payload };
    case SET_CN_ORDER_ID:
      return { ...state, orderId: action.payload };
    case SET_CN_SYSTEM_SUGGESTED_MODULES_LIST:
      return { ...state, systemSuggestedModulesList: action.payload };
    case SET_CN_FINAL_ASSET_TEMPLATES_LIST:
      return { ...state, finalAssetTemplatesList: action.payload };
    case SET_CN_SELECTED_TEMPLATE_ID:
      return { ...state, selectedTemplateId: action.payload };
    case SET_CN_FINAL_ASSET:
      return { ...state, finalAsset: action.payload };
    case SET_CN_MARKET_AND_LANGUAGE_INPUT_PROMPT:
      return { ...state, marketAndLanguageInputPrompt: action.payload };
    case SET_CN_LOCALISED_ASSET_DATA: {
      let payload = action.payload as { key: keyof LocalisedAssetDataType; data: LocalisedAssetDataType[keyof LocalisedAssetDataType] };
      console.log("REDUX SET_CN_LOCALISED_ASSET_DATA", payload, { ...state.localisedAssetData, [payload.key]: payload.data });
      return { ...state, localisedAssetData: { ...state.localisedAssetData, [payload.key]: payload.data } as LocalisedAssetDataType };
    }
    case SET_CN_TRANSLATION_LANGUAGE_INPUT_PROMPT:
      return { ...state, translationLanguageInputPrompt: action.payload };
    case SET_CN_TRANSLATED_ASSET_DATA: {
      let payload = action.payload as { key: keyof LocalisedAssetDataType; data: LocalisedAssetDataType[keyof LocalisedAssetDataType] };
      console.log("REDUX SET_CN_TRANSLATED_ASSET_DATA", payload, { ...state.translatedAssetData, [payload.key]: payload.data });
      return { ...state, translatedAssetData: { ...state.translatedAssetData, [payload.key]: payload.data } as LocalisedAssetDataType };
    }

    case RESET_CN_STATES:
      return initialState;
    default:
      return state;
  }
}
