import { Button, Dropdown, MenuProps, notification, Spin, Typography } from "antd";
import { Select, Space } from "antd";
import { translatedText as mockTranslatedText, translationApiResponse } from "../../Core/tranlationMockData";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import EditTranlatedTextModal from "./EditTranslatedTextModal";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";
import TranslationLoadingScreen from "./TranslationLoadingScreen";
import { siteConfig } from "../../../../Services";
import { SSE } from "sse.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setActiveScreenTMAction,
  setFTSelectedFileTMAction,
  setFTSelectedLanguageTMAction,
  setFTTranslateFileFlagTMAction,
  setFileTranslationAPIResponseTMAction,
  setFileTranslationResetDataTMAction
} from "../../../../Store/actions/_translationModuleActions";
import { AssetDataTypes, FileTranslationAPIResponseType } from "../../Core/modals";
import _translationModuleRequests from "../../../../Store/requests/_translationModuleRequests";
import { setNotificationMsgAction } from "../../../../Store/actions/_commonActions";
import { enumTMActiveScreen } from "../../Core/constant";
import MSOfficeDocsPreview from "./MSOfficeDocsPreview";

const { Text } = Typography;

// siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_TRANSLATION_MODULE;

const commonIFrameStyles = `
<style>
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Open+Sans:wght@400;500;600;700&family=Playfair+Display:wght@600;700;900&display=swap");

  body{
    font-family: Nunito Sans;
    // font-size: 12px;
    // white-space: pre;
    // text-wrap: pretty;
  }
</style>
`;

const scrollBarStyles = `
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 12px;
  }
</style>
`;

const languageButtonPrimaryStyle = {
  backgroundColor: "#034EA2",
  color: "white"
};

const languageButtonLinkStyle = {
  backgroundColor: "#fff",
  color: "#034EA2"
};

const officeExtensions = ["docx", "pptx", "xlsx"];

export default function FinalTranslationScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    package_id,
    translationAPIResponse: apiResponse = [],
    selectedFile,
    selectedLanguage,
    asset: { context, language_ids },
    translateFileFlag
  }: {
    package_id: number;
    translationAPIResponse: FileTranslationAPIResponseType;
    selectedFile: number;
    selectedLanguage: number;
    asset: AssetDataTypes;
    translateFileFlag: boolean;
  } = useSelector((state: any) => state?.translationModuleReducer?.uploadDocsData);

  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentDoc, setCurrentDoc] = useState<{ url: string }>({ url: "" });

  /**
   * {
   *    "e1888dc9427ce90e23e1f4a0a0417df89dab5f6eed37bc9d7e41a49760766f55": base64String,
   *    "f3888dc9427ce90e23e1f4a0a0417df89dab5f6eed37bc9d7e41a49760766f55": base64String,
   * }
   */
  const [pdfsCache, setPDFsCache] = useState<any>(JSON.parse(getLSKey("pdfsCache") || "[]"));

  const [editTranslatedText, setEditTranslatedText] = useState(false);
  const [translatedText, setTranslatedText] = useState("" /* mockTranslatedText */);
  const [translatedFileUrl, setTranslatedFileUrl] = useState("");
  const [loadingTranslationScreen, setLoadingTranslationScreen] = useState(true);
  const [loadingTranslatedText, setLoadingTranslatedText] = useState(true);

  const [availableLanguages, setAvailableLanguages] = useState<{ id: number; name: string }[]>([]);

  const [sourceHTMLDoc, setSourceHTMLDoc] = useState("");
  const [translatedHTMLDoc, setTranslatedHTMLDoc] = useState("");

  const getCustomSSE = (url: string, isJson: boolean = true, payload?: any) => {
    console.log("final translation screen, custom sse function call");
    siteConfig.BASE_URL = process.env.REACT_APP_API_URL_FOR_TRANSLATION_MODULE;

    var headers: Record<string, string> = {
      Authorization: localStorage.getItem(siteConfig.ACCESS_TOKEN) || ("" as string)
    };

    const sse = new SSE(siteConfig.BASE_URL + url, {
      headers,
      payload: isJson ? JSON.stringify(payload) : payload
    });

    return sse;
  };

  const checkSSEResponseComplete = () => {
    if (!apiResponse.length) return false;

    for (let file of apiResponse) {
      if (file.status !== "SUCCESS") {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    console.log("1. useEffect: SSE[]");

    if (!translateFileFlag && checkSSEResponseComplete()) {
      return;
    }

    translateFileFlag && dispatch(setFTTranslateFileFlagTMAction(false));

    console.log("Calling SSE...");

    const sse = getCustomSSE(siteConfig.TM_TRANSLATION_FILE, true, {
      context: context || "",
      package_id: package_id, //54,
      language_ids: language_ids || [] //[1, 2, 3, 4]
    });

    sse.addEventListener("end", (res: any) => {
      //"end" is also triggered when response has not been completed in 240s? Then data is set as "".
      console.log({ res });

      if (res.data) {
        const translationResponse = JSON.parse(res.data);
        dispatch(setFileTranslationAPIResponseTMAction(translationResponse));
      }
      sse.close();
    });

    sse.addEventListener("pending", (res: any) => {
      console.log({ res });
      const translationResponse = JSON.parse(res.data);
      dispatch(setFileTranslationAPIResponseTMAction(translationResponse));
    });

    sse.onerror = (err) => {
      sse.close();
      dispatch(setNotificationMsgAction("SSE error!"));
      console.log("SSE Error:", err);
    };

    return () => {
      console.log("Closing SSE connection...");
      sse.close();
    };
  }, []);

  //Update the APIResponse with the translated text. Translated Object in case of API response
  useEffect(() => {
    console.log("2. useEffect: [translatedText]");

    if (!apiResponse.length || translatedText === "") return; //translatedText === "" solves the issue where content gets replaced with blank.

    let fileObj, fileIndex, translationObj, translationObjIndex;

    for (let i = 0; i < apiResponse.length; i++) {
      const file = apiResponse[i];
      if (file.file_id === selectedFile) {
        fileObj = file;
        fileIndex = i;
        break;
      }
    }
    if (!fileObj) return;

    let tranlationsObj = fileObj.translations;

    for (let i = 0; i < tranlationsObj.length; i++) {
      const translationObject = tranlationsObj[i];

      if (translationObject.language_id === Number(selectedLanguage)) {
        translationObj = translationObject;
        translationObjIndex = i;
        break;
      }
    }
    if (!translationObj) return;

    if (translationObjIndex === undefined) return;

    //Don't update apiResponse if translatedText is same as before.
    if (translatedText === tranlationsObj[translationObjIndex].translation) {
      console.log("Same Translate text, returning");
      return;
    }

    console.log("Translate text updating...");

    let updatedTranslationObj = { ...translationObj, translation: translatedText };

    let updatedTranslationsObj = [...tranlationsObj];
    updatedTranslationsObj.splice(translationObjIndex, 1, updatedTranslationObj);

    let updatedFileObj = { ...fileObj, translations: updatedTranslationsObj };

    let updatedAPIResponseObj = [...apiResponse];
    if (fileIndex === undefined) return;
    updatedAPIResponseObj.splice(fileIndex, 1, updatedFileObj);

    dispatch(setFileTranslationAPIResponseTMAction(updatedAPIResponseObj));
  }, [translatedText]);

  useEffect(() => {
    console.log("3. useEffect: [selectedFile, selectedLanguage]");

    if (selectedFile && selectedLanguage) {
      let translatedObj = apiResponse
        ?.filter((file) => file.file_id === selectedFile)?.[0]
        ?.translations?.filter((translationobj: any) => translationobj.language_id === Number(selectedLanguage))?.[0];

      if (translatedObj?.status === "SUCCESS") {
        setTranslatedText(translatedObj.translation);
        translatedObj.translatedfile_url && setTranslatedFileUrl(translatedObj.translatedfile_url);
        setLoadingTranslatedText(false);
      } else {
        setLoadingTranslatedText(true);
      }
    }
    selectedFile && fetchOrSetPDFCache(selectedFile);
  }, [selectedFile, selectedLanguage]);

  useEffect(() => {
    console.log("4. useEffect: [apiResponse]");

    if (!apiResponse.length) return;

    const languages: any[] = [];

    let fileObj = apiResponse[0];

    console.log({ fileObj });
    if (fileObj && !availableLanguages.length) {
      fileObj.translations?.forEach((translationObj: any) => languages.push({ id: translationObj.language_id, name: translationObj.language_name }));
    }

    let defaultSelectedLanguage;

    if (!availableLanguages.length) {
      setAvailableLanguages(languages);
      if (!selectedLanguage) {
        defaultSelectedLanguage = languages[0].id;
        dispatch(setFTSelectedLanguageTMAction(languages[0].id));
      }
    }

    for (let file of apiResponse) {
      if (file.status === "STARTED" || file.status === "SUCCESS") {
        //Select the loaded file if there's no other files selected
        if (!selectedFile) {
          dispatch(setFTSelectedFileTMAction(file.file_id));
        } else if (selectedFile === file.file_id) {
          for (let translationObj of file.translations) {
            if (defaultSelectedLanguage === translationObj.language_id || selectedLanguage === translationObj.language_id) {
              if (translationObj.status === "SUCCESS") {
                setTranslatedText(translationObj.translation);
                setLoadingTranslatedText(false);
              } else {
                setLoadingTranslatedText(true);
              }
            }
          }
        }

        loadingTranslationScreen && setLoadingTranslationScreen(false);
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    console.log("5. useEffect: [pdfsCache]");
    setLSKey("pdfsCache", JSON.stringify(pdfsCache));
  }, [pdfsCache]);

  useEffect(() => {
    if (currentDoc.url?.endsWith(".html")) {
      (async () => {
        const sourceHTMLContent = await fetchHtmlContent(currentDoc.url);
        setSourceHTMLDoc(sourceHTMLContent);
      })();
    }
  }, [currentDoc]);

  useEffect(() => {
    if (translatedFileUrl?.endsWith(".html")) {
      (async () => {
        const translatedHTMLContent = await fetchHtmlContent(translatedFileUrl);
        setTranslatedHTMLDoc(translatedHTMLContent);
      })();
    }
  }, [translatedFileUrl]);

  function getLSKey(key: string) {
    return localStorage.getItem(key);
  }
  function setLSKey(key: string, pdf: string) {
    return localStorage.setItem(key, pdf);
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    console.log({ numPages });
    setNumPages(numPages);
  }

  function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    const bytes: any = [];
    uint8Array.forEach((byte) => {
      bytes.push(String.fromCharCode(byte));
    });
    console.log({ bytes });
    const base64String = btoa(bytes.join(""));
    return base64String;
  }

  function fetchOrSetPDFCache(fileId: number) {
    const file = apiResponse.filter((file) => file.file_id === fileId)[0];
    const fileHash = file.hash;

    //First condition is to prevent rerendering of loaded PDF on language change
    currentDoc.url !== file.url && setCurrentDoc({ url: file.url });
    return;

    //If Office Doc then don't cache
    let fileExtension = getFileExtension(file);
    if (fileExtension !== "pdf") {
      setCurrentDoc({ url: file.url });
      return;
    }

    if (pdfsCache[fileHash]) {
      setCurrentDoc(pdfsCache[fileHash]);
    } else {
      fetch(file.url)
        .then((res) => res.arrayBuffer())
        .then((res) => {
          let base64String = arrayBufferToBase64(res);
          setPDFsCache((prevCache: any) => ({ ...prevCache, [fileHash]: { url: "data:application/pdf;base64," + base64String } }));
          setCurrentDoc({ url: "data:application/pdf;base64," + base64String });
        });
    }
  }

  const getTranslationId = () => {
    return apiResponse
      .filter((file) => file.file_id === selectedFile)[0]
      ?.translations.filter((translationObj) => translationObj.language_id === selectedLanguage)[0]?.id;
  };

  const updateTranslatedText = async (updatedText: string) => {
    const translation_id = getTranslationId();
    const res = await _translationModuleRequests.updateTranslatedText({ translation_id, text: updatedText });

    if (!res || res.error) return false;
    return true;
  };

  const handleChange = (value: string) => {
    let fileId = Number(value);
    dispatch(setFTSelectedFileTMAction(fileId));
    console.log(`selected ${value}`);
  };

  const isLanguageTranslationAvailable = (languageId: number) => {
    for (let file of apiResponse) {
      if (file.file_id === selectedFile) {
        if (file.status === "SUCCESS") return true;

        //For file status:'PENDING'
        for (let translationObj of file.translations) {
          if (translationObj.language_id === languageId && translationObj.status === "SUCCESS") return true;
        }
      }
    }
    return false;
  };

  const downloadFile = async (filename: string, content: string, type: string) => {
    var element = document.createElement("a");
    type === "text" && element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    type === "html" && element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(content));

    if (officeExtensions.includes(type.toLowerCase()) || type.toLowerCase() === "pdf") {
      const response = await fetch(content); //To allow renaming
      const blob = await response.blob();
      element.setAttribute("href", window.URL.createObjectURL(blob));
    }

    element.setAttribute("download", filename);
    element.click();
  };

  const downloadSrcFile = async (filename: string, url: string) => {
    var element = document.createElement("a");
    const response = await fetch(url); //To allow renaming
    const blob = await response.blob();
    element.setAttribute("href", window.URL.createObjectURL(blob));
    element.setAttribute("download", filename);
    element.click();
  };

  const getTranslatedFileName = () => {
    let fileName = apiResponse.filter((file) => file.file_id === selectedFile)[0]?.filename;
    fileName = fileName.slice(0, fileName.lastIndexOf("."));
    let translatedLanguage = availableLanguages.filter((language) => language.id === selectedLanguage)[0]?.name;
    fileName += "_" + translatedLanguage;
    return fileName;
  };

  const getTranslatedPlainText = () => {
    if (iFrameRef.current?.contentDocument?.body) {
      return iFrameRef.current.contentDocument.body.innerText;
    } else return translatedText;
  };

  const getTranslatedText = (value: string) => {
    //@ts-ignore
    return `${t(I18N_MODULE_KEYS[`translation-module.FinalTranslationScreen.${value as string}`])}`;
  };

  const getFileExtension = (file: any = apiResponse.filter((file) => file.file_id === selectedFile)[0]) => {
    if (!file) return "";
    let fileName = file.filename;
    return fileName.slice(fileName.lastIndexOf(".") + 1) as string;
  };

  const isDoc = (file: any = apiResponse.filter((file) => file.file_id === selectedFile)[0]) => {
    return getFileExtension(file) === "docx";
  };

  const isOfficeDoc = () => {
    let file = apiResponse.filter((file) => file.file_id === selectedFile)[0];
    let fileExtension = getFileExtension(file);
    return officeExtensions.includes(fileExtension);
  };

  const isHTML = () => {
    let file = apiResponse.filter((file) => file.file_id === selectedFile)[0];
    let fileExtension = getFileExtension(file);
    return fileExtension === "html";
  };

  const fetchHtmlContent = async (url: string) => {
    let content = "";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch HTML content");
      }
      content = await response.text();
      return content;
    } catch (error) {
      console.error("Error fetching HTML:", error);
    }
    return content;
  };

  const fileDownloadOptions = [
    {
      key: "1",
      label: (
        <a style={{ fontSize: "12px" }} onClick={() => downloadFile(getTranslatedFileName() + ".txt", getTranslatedPlainText(), "text")}>
          Text File
        </a>
      )
    },
    {
      key: "2",
      label: (
        <a style={{ fontSize: "12px" }} onClick={() => downloadFile(getTranslatedFileName() + ".html", commonIFrameStyles + translatedText, "html")}>
          HTML File
        </a>
      )
    }
  ];

  const officeFileDownloadOptions = [
    {
      key: "3",
      label: (
        <a style={{ fontSize: "12px" }} onClick={() => downloadSrcFile(getTranslatedFileName() + `.${getFileExtension()}`, translatedFileUrl)}>
          {getFileExtension()?.toUpperCase()} File
        </a>
      )
    }
  ];

  //const downloadMenuitems: MenuProps["items"] = isOfficeDoc() ? officeFileDownloadOptions : fileDownloadOptions;
  const downloadMenuitems: MenuProps["items"] = officeFileDownloadOptions;

  return (
    <>
      {!loadingTranslationScreen && (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <style>
            {`
            .select-file .ant-select-selector{
                border-radius: 0px;
            }
            .select-file .ant-select-arrow{
                color: #034EA2;
            }
            `}
          </style>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text style={{ fontSize: "18px", fontWeight: "600" }}>{getTranslatedText("Translation is Done!")}</Text>
              <Text style={{ fontSize: "10px", color: "#66676B" }}>
                {getTranslatedText("Please select a file from the Dropdown to view the translated asset.")}
              </Text>
            </div>
            <div style={{ display: "flex", gap: "10px", fontSize: "12px" }}>
              <Button
                type="link"
                style={{ color: "#034EA2", fontWeight: 600 }}
                onClick={() => {
                  dispatch(setActiveScreenTMAction(enumTMActiveScreen.NOTHING));
                  dispatch(setFileTranslationResetDataTMAction());
                }}
              >
                ◄ {getTranslatedText("START AGAIN")}
              </Button>
              {/* <Button type="link" style={{ color: "#034EA2", fontWeight: 600 }}> */}
              <Button type="link" style={{ color: "rgba(102, 103, 107, 1)", fontWeight: 600 }} disabled>
                {getTranslatedText("GET YOUR QUALITY REPORT")}
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "rgba(230, 231, 232, 1)", borderRadius: "2rem", padding: "0 1rem" }}
                //onClick={saveTranslatedTextToTxtFile} // Replace this with Zip File API call
                disabled
              >
                {getTranslatedText("DOWNLOAD")}
              </Button>
            </div>
          </div>
          <br />
          <div style={{ display: "flex", gap: "10px", flex: "1 1 auto" }}>
            <div style={{ display: "flex", flex: "1.3", flexDirection: "column", gap: "5px" }}>
              <Text style={{ fontWeight: "600" }}>{getTranslatedText("Input")}</Text>
              <div
                style={{
                  backgroundColor: "#F1F7FF",
                  flex: "1 1 auto",
                  padding: "16px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px"
                }}
              >
                <Select
                  className="select-file"
                  defaultValue={apiResponse.filter((file) => file.file_id === selectedFile)?.[0]?.filename}
                  style={{ width: "200px" /* , margin: "6px" */ }}
                  onChange={handleChange}
                  options={apiResponse.map((file) => ({
                    value: file.file_id,
                    label: file.filename,
                    disabled: file.status === "PENDING",
                    title: file.status === "PENDING" ? "Translating..." : file.filename
                  }))}
                />
                <div style={{ overflow: isOfficeDoc() || isHTML() ? "hidden" : "hidden auto", flex: "1 1 0" }}>
                  {isOfficeDoc() ? (
                    <MSOfficeDocsPreview file={currentDoc} />
                  ) : isHTML() ? (
                    <iframe
                      srcDoc={commonIFrameStyles + scrollBarStyles + sourceHTMLDoc}
                      style={{ height: "100%", width: "100%", border: "solid lightgray 1px", borderRadius: "5px" }}
                    />
                  ) : (
                    <Document file={currentDoc} onLoadSuccess={onDocumentLoadSuccess} className={"coreclaimpdfviewer"}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                      </div>
                    </Document>
                  )}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flex: "2", flexDirection: "column", gap: "5px" }}>
              <Text style={{ fontWeight: "600" }}>{getTranslatedText("Output")}</Text>
              <div style={{ backgroundColor: "#F1F7FF", flex: "1 1 auto", padding: "16px", display: "flex", gap: "16px", borderRadius: "8px" }}>
                <div
                  style={{
                    flex: "4",
                    height: "100%",
                    backgroundColor: "white",
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    borderRadius: "6px"
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", flex: "none" }}>
                    <Text style={{ fontWeight: "600" }}>
                      {availableLanguages.filter((language: any) => language.id === selectedLanguage)?.[0]?.name}
                    </Text>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button
                        type="link"
                        style={{
                          color: loadingTranslatedText || isOfficeDoc() ? "lightgray" : "#034EA2",
                          fontWeight: 600,
                          display: "none", //"flex",
                          padding: 0,
                          fontSize: "12px",
                          textTransform: "uppercase"
                        }}
                        onClick={() => setEditTranslatedText(true)}
                        disabled={loadingTranslatedText || isOfficeDoc()}
                      >
                        {getTranslatedText("Edit")}
                      </Button>
                      <Dropdown menu={{ items: downloadMenuitems }} placement="bottomLeft" trigger={["click"]}>
                        <Button
                          type="link"
                          style={{
                            color: loadingTranslatedText ? "lightgray" : "#034EA2",
                            fontWeight: 600,
                            display: "flex",
                            padding: 0,
                            fontSize: "12px"
                          }}
                          disabled={loadingTranslatedText}
                          onClick={(e) => e.preventDefault()}
                        >
                          {getTranslatedText("DOWNLOAD")}
                        </Button>
                      </Dropdown>
                    </div>
                  </div>

                  {/* <div className="translated-content" style={{ overflow: "auto", flex: "1 1 0" }}>
                {translatedText}
              </div> */}
                  {!loadingTranslatedText && (
                    <div
                      style={{
                        overflow: isOfficeDoc() || isHTML() ? "hidden" : "hidden auto",
                        flex: "1 1 0",
                        ...(!isOfficeDoc() ? { border: "solid lightgray 1px", borderRadius: "5px" } : {})
                      }}
                    >
                      {isOfficeDoc() ? (
                        <MSOfficeDocsPreview file={{ url: translatedFileUrl }} />
                      ) : isHTML() ? (
                        <iframe
                          srcDoc={commonIFrameStyles + scrollBarStyles + translatedHTMLDoc}
                          style={{ height: "100%", width: "100%", border: "none" }}
                        />
                      ) : (
                        <Document file={{ url: translatedFileUrl }} onLoadSuccess={onDocumentLoadSuccess} className={"coreclaimpdfviewer"}>
                          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            {Array.from(new Array(numPages), (el, index) => (
                              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                          </div>
                        </Document>
                      )}
                    </div>
                  )}
                  {loadingTranslatedText && (
                    <div
                      style={{
                        flex: "1 1 auto",
                        border: "solid lightgray 1px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Spin />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    flex: "1",
                    height: "100%",
                    backgroundColor: "white",
                    padding: "16px",
                    borderRadius: "6px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px"
                  }}
                >
                  <Text style={{ fontWeight: "600" }}>
                    {getTranslatedText("Languages")}({availableLanguages.length})
                  </Text>
                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    {availableLanguages.map((language: any) => (
                      <Button
                        key={language.name}
                        type={language.id === selectedLanguage ? "primary" : "link"}
                        style={Object.assign(
                          { padding: "5px", borderRadius: "5px" /* textAlign: "left"  */ },
                          language.id === selectedLanguage ? languageButtonPrimaryStyle : languageButtonLinkStyle
                        )}
                        onClick={() => {
                          setLSKey("selectedLanguage", String(language.id));
                          dispatch(setFTSelectedLanguageTMAction(language.id));
                        }}
                        loading={!isLanguageTranslationAvailable(language.id)}
                      >
                        {language.name}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EditTranlatedTextModal
            editTranslatedText={editTranslatedText}
            setEditTranlsatedText={setEditTranslatedText}
            translatedText={translatedText}
            setTranslatedText={setTranslatedText}
            updateTranslatedText={updateTranslatedText}
          />
        </div>
      )}
      {loadingTranslationScreen && <TranslationLoadingScreen />}
    </>
  );
}
