import { Button, message, Modal } from "antd";
import "./FinalAssetPreviewModal.css";
import TinyMCE from "../../../IDetail Extract/Components/_TinyMCE";
import { Editor as TinyMCEEditor } from "tinymce";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FinalAsset } from "../../resources/types";
import { saveFinalAssetData } from "../../../../Store/requests/_contentNewtonRequests";
import { useAppDispatch } from "../../../../Store/hooks";
import { setCNFinalAsset } from "../../../../Store/actions/_contentNewtonActions";
import _iDetailExtractRequests from "../../../../Store/requests/_iDetailExtractRequests";
import { useTranslation } from "react-i18next";
import { I18N_MODULE_KEYS } from "../../../../Components/i18n/core/constants";

const scrollBarStyles = `
<style>
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 12px;
  }
</style>
`;

export const FinalAssetPreviewModal: React.FC<{
  edit: boolean;
  open: boolean;
  setOpen: Function;
  asset: FinalAsset;
}> = ({ edit, open, setOpen, asset }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [editHTMLOutput, setEditHTMLOutput] = useState(edit);
  const [finalHTMLOutput, setFinalHTMLOutput] = useState(""); //Stringified HTML code. finalHTMLOutputData.
  const [editedOnce, setEditedOnce] = useState(false); //Load TinyMCE for first edit, then hide it for subsequent edits
  const [saveChanges, setSaveChanges] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [discardTriggeredSave, setDiscardTriggeredSave] = useState(false); //Temporary state to differentiate between Save and Discard-Save. To prevent 'Content Saved' message when discarding.
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openExitEditModeModal, setOpenExitEditModeModal] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const editorRef = useRef<TinyMCEEditor>(null);

  const oncancel = () => {
    setOpen(false);
  };

  const onOk = () => {
    setOpen(false);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const successMessage = (text: string = "") => messageApi.open({ type: "success", content: text, duration: 1.5 });
  const errorMessage = (text: string = "") => messageApi.open({ type: "error", content: text, duration: 1.5 });
  const warningMessage = (text: string = "") => messageApi.open({ type: "warning", content: text, duration: 1.5 });
  const infoMessage = (text: string = "") => messageApi.info(text, 1.5);

  useEffect(() => {
    setEditHTMLOutput(edit);
  }, [edit]);

  useEffect(() => {
    //Don't update the state if editor is already opened. It would reset the cursor to starting position. And Undo would not work as the TinyMCE would be reset with this input.
    //Using setFinalHTMLOutput local state while saving to quickly reflect the changes.
    !editHTMLOutput && setFinalHTMLOutput(asset?.html);
  }, [asset?.html]);

  useEffect(() => {
    editHTMLOutput && !editedOnce && setEditedOnce(true);

    //If switching back from edit mode, update the local state contents with the Redux state
    //finalHTMLOutput is being used in iframe.
    !editHTMLOutput && editedOnce && setFinalHTMLOutput(asset?.html);
  }, [editHTMLOutput]);

  useEffect(() => {
    if (!saveChanges) return;
    setSaveChanges(false);

    //If this save has been triggered due to Discard-Save
    if (discardTriggeredSave) {
      setDiscardTriggeredSave(false);
      return;
    }

    const updatedContent = editorRef.current?.getContent() || "";

    saveFinalAssetData(asset?.asset_id, updatedContent).then((res) => {
      if (!res?.error) {
        //Dispatch saved notification

        //Updating the local state also to quickly reflect the changes
        //setFinalHTMLOutput(updatedContent);
        dispatch(setCNFinalAsset({ ...asset, html: updatedContent }));

        //dispatch(setNotificationMsgAction("Content saved!"));
        successMessage("Content saved!");

        console.log("Updated content Saved!");
      } else {
        //dispatch(setNotificationMsgAction("Saving failed! Please retry."));
        errorMessage("Saving failed! Please retry.");
        console.log("Saving failed!");
      }
    });
  }, [saveChanges]);

  useEffect(() => {
    if (!discardChanges) return;

    //Restore editor contents with last saved item
    editorRef.current?.setContent(asset?.html);

    //Save these changes in the editor. It would flip the 'Dirty' flag to false.
    editorRef.current?.execCommand("mceSave");

    setDiscardChanges(false);

    warningMessage("Content discarded!");
  }, [discardChanges]);

  const editorHasUnsavedChanges = () => {
    const tinymce = editorRef.current;
    return tinymce && !tinymce?.isNotDirty;
  };

  const onEditorChangesSaveCallback = async () => {
    setSaveChanges(true);
  };

  //Don't save the recent changes made after the last save
  const onEditorChangesCancelCallback = () => {
    setDiscardChanges(true);
    setDiscardTriggeredSave(true);
  };

  const saveEditorChangesHandler = () => {
    if (editorHasUnsavedChanges()) {
      //Save editor contents
      triggerSaveCommandTMCE();
    } else {
      //dispatch(setNotificationMsgAction("No recent changes to save."));
      infoMessage("No recent changes to save.");
      console.log("No recent changes to save");
    }
  };

  const triggerSaveCommandTMCE = () => {
    const tinymce = editorRef.current;
    tinymce?.execCommand("mceSave");
  };

  const discardEditorChangesHandler = () => {
    //Discard editor contents
    const tinymce = editorRef.current;
    console.log({ editorHasUnsavedChanges: editorHasUnsavedChanges() });
    //editorHasUnsavedChanges() && tinymce?.execCommand("mceCancel");

    if (editorHasUnsavedChanges()) {
      //Open discard modal
      setOpenDiscardModal(true);
    } else {
      //dispatch(setNotificationMsgAction("No unsaved changes to discard."));
      infoMessage("No unsaved changes to discard.");
      console.log("No unsaved changes to discard");
    }
  };

  const exitEditModeHandler = () => {
    if (editorHasUnsavedChanges()) {
      console.log("Changes would be lost!");
      setOpenExitEditModeModal(true);
    } else setEditHTMLOutput(false);
  };

  const previewEditorChangesHandler = () => {
    const tinymce = editorRef.current;
    //@ts-ignore
    // window.tinymce?.activeEditor?.execCommand("mcePreview");
    tinymce?.execCommand("mcePreview");
  };

  const cancelDiscardModalHandler = () => {
    //close the dialog
    setOpenDiscardModal(false);
  };

  const loseChangesHandler = () => {
    triggerDiscardCommandTMCE();

    //close the dialog
    setOpenDiscardModal(false);
  };

  const triggerDiscardCommandTMCE = () => {
    editorRef.current?.execCommand("mceCancel");
  };

  const showTinyMCEEditor = () => {
    setEditHTMLOutput((p) => !p);
  };

  const uploadImageToS3 = async (blobInfo: any) => {
    const formData: FormData = new FormData();
    formData.append("file", blobInfo.blob());

    return new Promise(async (resolve, reject) => {
      //TOD: Remove this
      const res = await _iDetailExtractRequests.uploadImage(asset?.asset_id, formData);

      if (res) {
        res?.data && resolve(res?.data?.url);
        console.log(res.data?.url);
      }
    }) as Promise<string>;
  };

  const getTranslatedText = (value: string) => {
    return t(I18N_MODULE_KEYS[`idetailextract._CoreClaimMain.FinalHTMLOutputModal.${value}` as keyof typeof I18N_MODULE_KEYS]);
  };

  const getCommonTranslatedText = (value: string) => {
    return t(I18N_MODULE_KEYS[`content.text.${value}` as keyof typeof I18N_MODULE_KEYS]);
  };

  return (
    <Modal
      open={open}
      className="cn-fullscreen-variable-width-modal final-asset-viewer"
      title={"Assets Preview"}
      onCancel={oncancel}
      onOk={onOk}
      onClose={oncancel}
      styles={{ body: { display: "flex" } }}
      //   styles={{ body: { height: "calc(90vh - 84px)", display: "flex", flexDirection: "column", gap: "10px" } }} //20 + 12 + 32 + 20
    >
      {!editHTMLOutput && (
        <div style={{ flex: 1, height: "100%", overflow: "hidden" }}>
          <iframe
            srcDoc={scrollBarStyles + asset?.html}
            style={{ flex: "1 1 auto", border: "solid gray 1px", borderRadius: "5px", width: "100%", height: "100%" }}
          />
        </div>
      )}
      {editedOnce && (
        <TinyMCE
          editorRef={editorRef as MutableRefObject<TinyMCEEditor>}
          content={asset.html}
          hide={!editHTMLOutput}
          onEditorChangesSaveCallback={onEditorChangesSaveCallback}
          onEditorChangesCancelCallback={onEditorChangesCancelCallback}
          uploadImageToS3={uploadImageToS3}
        />
      )}
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="primary" onClick={handleEdit}>
          Edit
        </Button>
        <Button type="primary" onClick={handleDownload}>
          Download
        </Button>
      </div> */}
    </Modal>
  );
};
