import { Col, Row, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ModulesPreviewModal } from "../ModulesPreview/ModulesPreviewModal";
import { ModuleType } from "../types";
import { useAppDispatch } from "../../../../../Store/hooks";

export const SSMRendering = ({ modules, systemSuggestedModules }: { modules: ModuleType[]; systemSuggestedModules: ModuleType[] }) => {
  const [openPreview, setOpenPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState<ModuleType>();
  const [previewedModule, setPreviewedModule] = useState("ssm");

  const dispatch = useAppDispatch();

  const renderTile = (type: string = "!ssm") => {
    let modulesList = [];
    if (type === "ssm") {
      modulesList = systemSuggestedModules;
    } else {
      modulesList = modules;
    }
    // modulesList = [...modulesList];
    // modulesList.push(...modulesList, ...modulesList, ...modulesList);

    return (
      <Row gutter={8} style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto", overflowY: "hidden", width: "100%", flex: 1, padding: "5px" }}>
        {modulesList?.map((item) => (
          <Col span={modules?.length < 2 ? 24 : 12} key={item.id}>
            <div className="checkbox-tile content-container">
              <div style={{ /* height: "250px", */ height: "100%", padding: "40px", background: "#f0f7ff", color: "#5f5f5f", borderRadius: "9px" }}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Module ID: </span>
                  <span>{item.module_id}</span>
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Primary Claim: </span>
                  <span>{item.claims.find((claim) => claim.type.toLowerCase() === "primary")?.text}</span>
                </div>
              </div>
              <Tooltip title="Preview">
                <EyeOutlined
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    fontSize: "18px",
                    color: "#fff",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: "3px",
                    cursor: "pointer"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreviewItem(item as ModuleType);
                    setOpenPreview(true);
                    setPreviewedModule(type);
                  }}
                />
              </Tooltip>
            </div>
          </Col>

        ))}
      </Row>
    );
  };

  return (
    <Row style={{ display: "flex" }}>
      <Col span={modules?.length < 2 ? 6 : 12} style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ fontWeight: 600, marginLeft: "5px" }}> Selected Modules</div>
        {renderTile()}
      </Col>

      {
        systemSuggestedModules?.length > 0 ? (
          <Col
            span={modules?.length < 2 ? 6 : 12}
            style={{ borderLeft: "1px solid black", paddingLeft: "8px", display: "flex", flexDirection: "column" }}
          >
            <div style={{ fontWeight: 600, marginLeft: "5px" }}>System Suggested Modules</div>
            {renderTile("ssm")}
          </Col>
        ) : null
      }



      {previewItem && openPreview && (
        <ModulesPreviewModal
          open={openPreview}
          setOpen={setOpenPreview}
          previeweditem={previewItem}
          modulesList={previewedModule === "ssm" ? systemSuggestedModules : modules}
        />
      )}
    </Row>
  );
};
