import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Collapse, Flex, Image, List, Menu, Row, Spin, Tag, Tooltip, Typography } from "antd"; // Import Image component from Ant Design
import { useSelector, useDispatch } from "react-redux";
import { MessageType, ResearchAgentType } from "./Core/constants";
import { PROJECT_ICONS } from "../../../Assets/Img/_DIcons";
import { AlignLeftOutlined, DownOutlined, UpOutlined, CaretRightOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  setInitialResearchAgentAction,
  setLoadingforReportGeneration,
  setMessageIId,
  setNextId,
  setPreviousId,
  setReportGenerationType
} from "../../../Store/actions/_semanticSearchActions";
import { exportArtefactReport, getAllConversations, semanticIntereseted } from "../../../Store/requests/_semanticSearchRequests";
import { setNotificationMsgAction } from "../../../Store/actions/_commonActions";
import { I18N_MODULE_KEYS } from "../../../Components/i18n/core/constants";
import {
  nextinsightanswerwordingstyle,
  nextinsightmagicpen,
  nextinsightmenu,
  nextinsightpreviousnextbutton,
  nextinsightsearchquerycontainer,
  nextinsightsubmenu
} from "./SearchQueryScreens/StylesFolderForNextInsight/_NextInsightPageStyleConstant";
import { NextInsightSimilaritySearch } from "./SearchQueryScreens/NEXTInsightComponents/_NextInsightSimilaritySearch";
import { ModalComponent } from "./SearchQueryScreens/_ModalComponent";

import Markdown from "markdown-to-jsx";


interface NextInsightPageProps {
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string[]>>;
  internalVarA: number;
  setInternalVarA: React.Dispatch<React.SetStateAction<number>>;
  internalVarB: string;
  setInternalVarB: React.Dispatch<React.SetStateAction<string>>;
  setStepperForInternalVarb: React.Dispatch<React.SetStateAction<number>>;
  stepperForinternalVarB: number;
}

export const ResearchAgentComponent:React.FC<NextInsightPageProps> = ({
  stepperForinternalVarB,
  setStepperForInternalVarb,
  internalVarA,
  setInternalVarA,
  internalVarB,
  setInternalVarB,
  columns,
  setColumns,
}) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { cardData, cardDataCollection, searchType ,Format, conversationId, searchQuery} = useSelector((state: any) => state.semanticSearchReducer);
  const { researchAgent }: { researchAgent: ResearchAgentType } = useSelector((state: any) => state?.semanticSearchReducer);
  const { isLoading }: { isLoading: boolean } = useSelector((state: any) => state?.nonPersistedReducer);
  const searchQueryColumnRef = useRef<HTMLDivElement | null>(null);
  const [searchQueryColumnHeight, setSearchQueryColumnHeight] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState("65vh"); // Default maxHeight
  const [Interested, setisInterested] = useState<any>(cardData?.interested);
  const [copiedValue, setCopiedValue] = useState("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, setmenuItems] = useState([]);
  const [isMenuOpenForConversation, setIsMenuOpenForConversation] = useState(false);
  const [menuItemsForConversation, setmenuItemsForConversation] = useState([]);
  const [isLoadingForPro, setIsLoadingForPro] = useState(true);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuRefConversation = useRef<HTMLDivElement | null>(null);
  const [isopenforFolderTime, setisopenforFolderTime] = useState<boolean>(false);
  const [isSavingArtifact, setIsSavingArtifact] = useState<boolean>(false);
  const [drawerimageNameforsimilaritysearch, setdrawerimageforsimilaritysearch] = useState("");
  const [drawerfilenameforsimilaritysearch, setdrawerfilenameforsimilaritysearch] = useState<any>("");
  const [drawervideoNameforsimilaritysearch, setdrawervideoforsimilaritysearch] = useState("");
  const [draweraudioNameforsimilaritysearch, setdraweraudioforsimilaritysearch] = useState("");
  const [componentVisible, setComponentVisible] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<string | null>("");
  const [componentFormatVisible, setComponentFormatVisible] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

console.log(isLoading)
console.log("u are finding me beacsue to check loaidng for local search it is important hehe ")
 
  useEffect(() => {
    // Scroll to top when searchQuery changes
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, [searchQuery]); // Dependency on searchQuery




  useEffect(() => {
    return () => {
      dispatch(setInitialResearchAgentAction());
    };
  }, []);

  useEffect(() => {
    console.log(cardData, "card data research");
  }, [cardData]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
    if (menuRefConversation.current && !menuRefConversation.current.contains(event.target as Node)) {
      setIsMenuOpenForConversation(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpenForConversation) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpenForConversation]);

  useEffect(() => {
    if (searchQueryColumnRef.current) {
      const height = searchQueryColumnRef.current.offsetHeight;
      setSearchQueryColumnHeight(height);
      console.log(height + "this is height of searchquery");
    }
  }, []);

  useEffect(() => {
    // Set isLoadingForPro to true initially
    if (true) {
      setIsLoadingForPro(true);

      // After 3 seconds, set isLoadingForPro to false
      const timeout = setTimeout(() => {
        setIsLoadingForPro(false);
      }, 5000);

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeout);
    }
  }, []); // Empty

  useEffect(() => {
    const getAllConversationsById = async () => {
      const res = await getAllConversations(conversationId);
      setmenuItemsForConversation(res?.data);
      console.log(res);
    };
    if (isMenuOpenForConversation) {
      getAllConversationsById();
    }
  }, [isMenuOpenForConversation]);

  console.log("sssssssss");
  console.log(cardData);
  useEffect(() => {
    const handleResize = () => {
      const innerHeight = window.innerHeight;
      const newMaxHeight = innerHeight <= 600 ? "60vh" : innerHeight <= 700 ? "63vh" : "65vh";
      setMaxHeight(newMaxHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize); // Listen for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the listener
    };
  }, []); // Empty dep

  const handleMenuClick = (e: any) => {
    console.log(menuItems);
    dispatch(setReportGenerationType(e));
    setIsMenuOpen(false);
    dispatch(setLoadingforReportGeneration(true));
    console.log();
  };
  const handleMenuClickForConversation = (e: any) => {
    console.log(menuItems);
    setIsMenuOpenForConversation(false);
    console.log();
  };

  const handlePreviousCard = async () => {
    console.log(cardData?.previous_message);
    if (cardData?.previous_message && cardData?.previous_message !== null) {
      dispatch(setPreviousId(cardData?.previous_message));
    }
  };

  const handleNextCard = async () => {
    console.log(cardData?.next_message && cardData?.next_message !== null);
    if (cardData?.next_message && cardData?.next_message !== null) {
      dispatch(setNextId(cardData?.next_message));
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent form submission, if using within a form
        if (buttonRef.current) {
          buttonRef.current.click(); // Trigger button click event
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const openS3LinkInNewTab = (link: any) => {
    window.open(link, "_blank");
  };

  const updateInterest = async (val: any) => {
    console.log("I have entered inside update");
    const res = await semanticIntereseted({
      id: cardData?.id,
      interested: val
    });
    console.log(res);
  };

  const copyValueToClipboard = () => {
    console.log("clikc to copy");
    console.log(Format);
    if (Format === 3) {
      navigator.clipboard
        .writeText(cardData?.table_answer)
        .then(() => {
          // Set a state or provide a message indicating successful copy
          setCopiedValue(cardData?.table_answer);
          console.log("Value copied to clipboard:", cardData?.table_answer);
          dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.copy"])));
        })
        .catch((error) => {
          console.error("Unable to copy:", error);
          dispatch(setNotificationMsgAction("Text Unable to Copy"));
          // Handle error if copying fails
        });
    } else if (Format === 2) {
      navigator.clipboard
        .writeText(cardData?.list_answer)
        .then(() => {
          // Set a state or provide a message indicating successful copy
          setCopiedValue(cardData?.list_answer);
          console.log("Value copied to clipboard:", cardData?.list_answer);
          dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.copy"])));
        })
        .catch((error) => {
          console.error("Unable to copy:", error);
          dispatch(setNotificationMsgAction("Text Unable to Copy"));
          // Handle error if copying fails
        });
    } else {
      navigator.clipboard
        .writeText(cardData?.answer)
        .then(() => {
          // Set a state or provide a message indicating successful copy
          setCopiedValue(cardData?.answer);
          console.log("Value copied to clipboard:", cardData?.answer);
          dispatch(setNotificationMsgAction(t(I18N_MODULE_KEYS["semantic.search.result.notification.copy"])));
        })
        .catch((error) => {
          console.error("Unable to copy:", error);
          dispatch(setNotificationMsgAction("Text Unable to Copy"));
          // Handle error if copying fails
        });
    }
  };


  const showModalForFormat = () => {
    setComponentFormatVisible(true);
  };

  const exportMessageAsArtifact = async () => {
    setIsSavingArtifact(true);
    const link = await exportArtefactReport({ semantic_id: cardData?.id });
    if (link) {
      dispatch(setNotificationMsgAction("Saved as Artifact"));
      setIsSavingArtifact(false);
      return;
    }
    dispatch(setNotificationMsgAction("Error in saving Artifact"));
    setIsSavingArtifact(false);
  };
  const handleMessageId = async (id: number) => {
    console.log(id + "for the message id for menu ");
    dispatch(setMessageIId(id));
  };
  const toggleMenu = () => {
    console.log("Clicked on menu item:");
    console.log(menuItems);
    setIsMenuOpenForConversation(!isMenuOpenForConversation);
  };

  const showComponentForChunks = (s: string, name: string) => {
    // Create an object to map file types to state updates
    const fileTypeState: Record<string, () => void> = {
      "Document": () => {
        setdrawerfilenameforsimilaritysearch(name);
      },
      "Image": () => {
        setdrawerimageforsimilaritysearch(name);
      },
      "Video": () => {
        setdrawervideoforsimilaritysearch(name);
        console.log("opened modal with " + name)
      },
      "Audio": () => {
        setdraweraudioforsimilaritysearch(name);
      },
    };
  
    // Set default state for unknown types
    const setDefaultState = () => {
      setdrawerfilenameforsimilaritysearch("");
      setdrawerimageforsimilaritysearch("");
      setdrawervideoforsimilaritysearch("");
      setdraweraudioforsimilaritysearch("");
    };
  
    // Set component visibility and selected file type
    setComponentVisible(true);
    setSelectedFileType(s);
    
    // Reset all types first
    setDefaultState();
    
    // Apply the specific update based on the file type
    if (fileTypeState[s]) {
      fileTypeState[s]();
    } else {
      // Handle unknown file types if needed
      console.warn(`Unknown file type: ${s}`);
    }
  };

  const hideComponent = () => {
    setComponentVisible(false);
  };

  const hideModalForFormat = () => {
    setComponentFormatVisible(false);
  };

  return (
    <>
    <Col span={17}>
    <Card className="card-element">
      <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "10px" }}>
        <div style={{ paddingTop: "0", display: "flex", gap: "5px" }} ref={searchQueryColumnRef}>
          <div style={nextinsightsearchquerycontainer}>
            {" "}
            <div ref={menuRefConversation} style={{fontSize:'17px'}}>
              {searchQuery}
              <img
                src={PROJECT_ICONS.ARROW}
                style={{
                  cursor: "pointer",
                  marginLeft: "14px",
                  marginBottom: "2px",
                  height: "8px",
                  transform: !isopenforFolderTime ? "rotate(180deg)" : "rotate(0deg)"
                }}
                onClick={toggleMenu}
              />
              {isMenuOpenForConversation && (
                <Menu
                  onClick={handleMenuClickForConversation}
                  style={{
                    maxHeight: "350px",
                    overflowY: "scroll",
                    maxWidth: "800px",
                    position: "absolute",
                    boxShadow: "0px 0px 10px #0000001F",
                    border: "1px solid black",
                    zIndex: 10000,
                    marginTop: "05px",
                    marginRight: "50px",
                    borderRadius: "10px"
                  }}
                >
                  {menuItemsForConversation &&
                    menuItemsForConversation.map((item: any) => (
                      <Menu.Item key={item.id} onClick={() => handleMessageId(item.id)}>
                        {item.question}
                      </Menu.Item>
                    ))}
                </Menu>
              )}
            </div>
          </div>
        </div>

        <Row>
          <Col style={{ paddingTop: "1px", display: "flex", gap: "10px" }}>
            {isSavingArtifact ? (
              <>
                <Spin style={{ paddingTop: "15px", paddingRight: "15px" }}></Spin>
              </>
            ) : (
              <div
                style={{ paddingTop: "15px", color: "#1E4DA1", fontWeight: "700", cursor: "pointer" }}
                onClick={() => {
                  exportMessageAsArtifact();
                }}
              >
                SAVE AS ARTIFACT
              </div>
            )}
            {isMenuOpen && (
              <div ref={menuRefConversation} style={nextinsightmenu}>
                <Menu style={nextinsightsubmenu}>
                  <Menu.Item
                    onClick={() => {
                      handleMenuClick("Powerpoint");
                    }}
                  >
                    <Image src={PROJECT_ICONS.PPT} style={{ paddingRight: "10px" }} />
                    Powerpoint
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      handleMenuClick("PDF");
                    }}
                  >
                    <Image src={PROJECT_ICONS.FRAME} style={{ paddingRight: "10px" }} />
                    PDF
                  </Menu.Item>
                </Menu>
              </div>
            )}
            {/* <div style={nextinsightmagicpen} onClick={showModalForFormat}>
              <Image src={PROJECT_ICONS.MAGICPEN} preview={false} />
            </div> */}

<div style={nextinsightmagicpen} onClick={showModalForFormat}>
              <Image src={PROJECT_ICONS.MAGICPEN} preview={false} />
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ display: "flex", paddingTop: searchQueryColumnHeight < 52 ? "0px" : "10px", paddingRight: "10px", paddingLeft: "8px" }}>
        <Row style={{}}>
          <img src={PROJECT_ICONS.COPY} style={{ paddingRight: "16px", cursor: "pointer", height: "20px" }} onClick={copyValueToClipboard} />
          <img src={PROJECT_ICONS.SHARE_ICON} style={{ paddingRight: "16px", cursor: "not-allowed", height: "20px" }} />
          {Interested === true ? (
            <img
              src={PROJECT_ICONS.THUMBS_UP_SOLID}
              style={{ paddingRight: "20px", cursor: "pointer" }}
              onClick={() => {
                updateInterest(null);
                setisInterested(null);
              }}
            ></img>
          ) : (
            <img
              src={PROJECT_ICONS.THUMBS_UP}
              style={{ paddingRight: "20px", cursor: "pointer" }}
              onClick={() => {
                updateInterest(true);
                setisInterested(true);
              }}
            ></img>
          )}
          {Interested === false ? (
            <img
              src={PROJECT_ICONS.THUMBS_UP_SOLID}
              style={{ transform: "rotate(180deg)", paddingLeft: "20px", cursor: "pointer" }}
              onClick={() => {
                updateInterest(null);
                setisInterested(null);
              }}
            ></img>
          ) : (
            <img
              src={PROJECT_ICONS.THUMBS_DOWN}
              style={{ paddingRight: "20px", cursor: "pointer" }}
              onClick={() => {
                updateInterest(false);
                setisInterested(false);
              }}
            ></img>
          )}
          {cardDataCollection && cardDataCollection.length >= 2 ? (
            <>
              <div style={nextinsightpreviousnextbutton} onClick={handlePreviousCard}>
                PREVIOUS
              </div>
              <div style={nextinsightpreviousnextbutton} onClick={handleNextCard}>
                NEXT
              </div>
            </>
          ) : (
            <></>
          )}
        </Row>
      </div>

      <div
        style={{
          overflowY: "scroll",
          maxHeight: `calc(${maxHeight} - ${searchQueryColumnHeight}px)`,
          overflowX: "hidden",
          marginTop: "5px",
          paddingBottom: "25px",
          marginBottom: "20px"
        }}
        ref={scrollContainerRef}
      >
        {" "}
        <TypedAnimation />
        <>
        <Col span={24} style={{ flex: "1", paddingTop: "10px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", paddingTop: "5px" }}>
            <Image src={PROJECT_ICONS.INSIGHTCARD2} style={{ fontSize: "16px" }} preview={false} />
              <p style={{ marginLeft: "5px", fontSize: "16px", fontWeight: "700" }}>Sources</p>
            </div>
          </Col>
        {(researchAgent?.isLoading || isLoading) ? (
          <div style={{ fontWeight: 'bolder', fontSize: '14px', margin: '10px',marginLeft:'20px' }}>
          Sources are loading, will be available after the search process is completed.
          <Spin style={{marginLeft:'10px'}}></Spin>
        </div>
              ):(<>
        <Col span={24} style={{ paddingTop: "5px" }}>
              
              <div>
                <Row gutter={24}>
                  {cardData?.filenames && Object.entries(cardData.filenames).length > 0 ? (
                    Object.entries(cardData.filenames).length <= 4 ? (
                      Object.entries(cardData.filenames).length >= 1 ? (
                        Object.entries(cardData.filenames).map(([name, link]) => (
                          <Col span={5} key={name}>
                            <Tooltip title={name}>
                              <div style={{ position: "relative", paddingTop: "10px", cursor: "pointer" }}>
                                <div
                                  style={{
                                    padding: "10px",
                                    backgroundImage: `url(${PROJECT_ICONS.INSIGHTCARD3})`,
                                    backgroundSize: "cover",
                                    borderRadius: "15px",
                                    height:'90px'
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                      marginRight: "10px",
                                      color: "#66676B",
                                      height: "44px"
                                    }}
                                    onClick={() => openS3LinkInNewTab(link)}
                                  >
                                    {name?.slice(0, 25)}...
                                  </p>
                                
                                </div>
                              </div>
                            </Tooltip>
                          </Col>
                        ))
                      ) : (
                        <Col span={5}>
                          <Tooltip>
                            <div style={{ position: "relative", paddingTop: "10px", cursor: "pointer" }}>
                              <div
                                style={{
                                  padding: "10px",
                                  backgroundImage: `url(${PROJECT_ICONS.INSIGHTCARD3})`,
                                  backgroundSize: "cover",
                                  borderRadius: "15px",
                                  height:'90px'

                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "14px",
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                    marginRight: "10px",
                                    color: "#66676B",
                                    height: "44px"
                                  }}
                                >
                                  Not Found
                                </p>
                              </div>
                            </div>
                          </Tooltip>
                        </Col>
                      )
                    ) : (
                      <>
                        {Object.entries(cardData.filenames)
                          .slice(0, 4)
                          .map(([name, link]) => (
                            <Col span={5} key={name}>
                              <Tooltip title={name}>
                                <div style={{ position: "relative", paddingTop: "10px", cursor: "pointer" }}>
                                  <div
                                    style={{
                                      padding: "10px",
                                      backgroundImage: `url(${PROJECT_ICONS.INSIGHTCARD3})`,
                                      backgroundSize: "cover",
                                      borderRadius: "15px",
                                      height:'90px'
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        marginRight: "10px",
                                        color: "#66676B"
                                      }}
                                      onClick={() => openS3LinkInNewTab(link)}
                                    >
                                      {name.slice(0, 25)}...
                                    </p>
                                    
                                  </div>
                                </div>
                              </Tooltip>
                            </Col>
                          ))}
                        <Tooltip title="View all reference files">
                          <Col span={4}>
                            <Tooltip>
                              <div style={{ position: "relative", paddingTop: "10px", cursor: "pointer" }}>
                                <div
                                  style={{
                                    padding: "10px",
                                    backgroundImage: `url(${PROJECT_ICONS.INSIGHTCARD3})`,
                                    backgroundSize: "cover",
                                    borderRadius: "15px",
                                    height:'90px'

                                  }}
                                >
                                  <div style={{ paddingBottom: "20px" }}>
                                    <Tooltip>
                                      <Image src={PROJECT_ICONS.CHAT} style={{ height: "22px", paddingLeft: "2px" }} preview={false}></Image>
                                    </Tooltip>
                                    <Tooltip>
                                      <Image src={PROJECT_ICONS.CHAT} style={{ height: "22px", paddingLeft: "15px" }} preview={false}></Image>
                                    </Tooltip>
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                      marginRight: "10px",
                                      color: "#66676B"
                                    }}
                                    onClick={() => {
                                    }}
                                  >
                                    +{Object.entries(cardData.filenames).length - 4} View More
                                  </p>
                                </div>
                              </div>
                            </Tooltip>
                          </Col>
                        </Tooltip>
                      </>
                    )
                  ) : (
                    <>
                      <Col span={5}>
                        <Tooltip>
                          <div style={{ position: "relative", paddingTop: "10px", cursor: "pointer" }}>
                            <div
                              style={{
                                padding: "10px",
                                backgroundImage: `url(${PROJECT_ICONS.INSIGHTCARD3})`,
                                backgroundSize: "cover",
                                borderRadius: "15px",
                                height:'90px'
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  marginRight: "10px",
                                  color: "#66676B"
                                }}
                              >
                                Not found any source
                              </p>
                            </div>
                          </div>
                        </Tooltip>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </Col>
            </>)}
          <Col span={24} style={{ flex: "1", paddingTop: "15px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", paddingTop: "15px" }}>
              <AlignLeftOutlined style={{ fontSize: "16px" }} />
              <p style={{ marginLeft: "5px", fontSize: "16px", fontWeight: "700" }}>Answer</p>
            </div>
          </Col>
          <div style={{ paddingTop: "5px", overflow: "auto", marginTop: "3px", paddingLeft: "20px" }}>
            {(researchAgent?.isLoading || isLoading) ? (
              <div style={{ fontWeight: 'bolder', fontSize: '14px', margin: '5px' }}>
              Answer is loading, will be available after the search process is completed.
              <Spin style={{marginLeft:'10px'}}></Spin>
            </div>
            ) : (
              <>
                {cardData ? (
                  Format === 3 ? (
                    <>
                      {cardData?.table_result === "" ? (
                        <>
                          <div
                            // Make sure to provide a unique key when mapping in React
                            style={nextinsightanswerwordingstyle}
                          >
                            Not found any insight
                          </div>
                        </>
                      ) : (
                        <div
                          className="researcher-agent-table"
                          dangerouslySetInnerHTML={{ __html: cardData?.table_result }}
                          style={{ padding: "10px" }}
                        >
                          {/* Apply inline styles to the HTML received from API */}
                        </div>
                      )}

                      {/* <MarkdownTable markdownString={cardData?.result} /> */}
                    </>
                  ) : Format === 2 ? (
                    cardData?.list_result ? (
                      <div style={nextinsightanswerwordingstyle}>
                        <Markdown>{cardData?.list_result}</Markdown>
                      </div>
                    ) : (
                      <div style={nextinsightanswerwordingstyle}>- Not found any insight</div>
                    )
                  ) : cardData?.result ? (
                    <div className="researcher-agent" style={nextinsightanswerwordingstyle}>
                    <Markdown>{cardData?.result}</Markdown>
                  </div>
                  ) : (
                    <div style={nextinsightanswerwordingstyle}>Not found any insight</div>
                  )
                ) : (
                  <>
                    {" "}
                    <div
                      // Make sure to provide a unique key when mapping in React
                      style={nextinsightanswerwordingstyle}
                    >
                      Not found any insight
                    </div>
                  </>
                )}
              </>
            )}
            {/* <SemanticTabs/>
            <SimilaritySearch/> */}
          </div>
        </>
      </div>
    </Card>
    </Col>
    <Col span={7} style={{ paddingLeft: "20px" }}>
    <Card className="card-element2">
    {(researchAgent?.isLoading || isLoading) ? (
      <>
                  <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'500px',flexDirection:'column',gap:'15px'}}>
                   <div style={{fontWeight:'bold', fontSize:'16px'}}>Relevant Files are being loaded</div>

                    <Flex justify="center" style={{ width: "100%",height:'100%' }}>
                      <Spin />
                    </Flex>
                    </div>
                    </>
                ): 
                (
     <>
     <NextInsightSimilaritySearch showComponentForChunks={showComponentForChunks}/>
     </>)}
    </Card>
  </Col>


  {componentVisible && (
        <ModalComponent
          onHide={hideComponent}
          fileType={selectedFileType}
          imageName={drawerimageNameforsimilaritysearch}
          videoName={drawervideoNameforsimilaritysearch}
          audioName={draweraudioNameforsimilaritysearch}
          fileName={drawerfilenameforsimilaritysearch}
        />
      )}

    </>
  );
};

const { Panel } = Collapse;

const { Text } = Typography;

const TypedAnimation = () => {
  const { researchAgent }: { researchAgent: ResearchAgentType } = useSelector((state: any) => state?.semanticSearchReducer);

  console.log(researchAgent, "typed animation");

  const { cardData,searchType } = useSelector((state: any) => state?.semanticSearchReducer);

  console.log(cardData, "card data");

  const scrollRef: any = useRef(null); // For auto-scrolling

  // Auto scroll to the bottom when new items are added
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [researchAgent?.data]);

  return (
    <Col span={24} style={{ marginTop: "20px" }}>
      <Collapse
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
        style={{ padding: "0px" }}
      >
        <Panel
          header={
            <Flex>
              {/* <p style={{ fontWeight: "600", fontSize: "18px" }}>Research Agent</p> */}
              <p style={{ fontWeight: "600", fontSize: "18px" }}>{searchType === "Local" ? "Steps":"Brand IQ Agent"}</p>
            </Flex>
          }
          key="1"
          className="site-collapse-custom-panel"
        >
          <div style={{ background: "#F4F6F9", padding: "17px", paddingLeft: "20px", borderRadius: "10px" }}>
            <div>
              <div
                ref={scrollRef}
                style={{
                  height: "280px",
                  overflowY: "auto",
                  border: "1px solid #f0f0f0",
                  padding: "10px"
                }}
              >
                <List
                  dataSource={researchAgent?.data}
                  renderItem={(item) => (
                    <List.Item>
                      <Text>{`${item?.output}`}</Text>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </Col>
  );
};