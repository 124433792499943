import { FC, useMemo, useState, useRef } from "react";
import { Avatar, Button, Card, Col, Row, Typography, Space, Checkbox, Input, Image, Tooltip, Drawer, Spin, Modal, Menu } from "antd";
import {
  AlignLeftOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PROJECT_ICONS } from "../../../../../Assets/Img/_DIcons";
import { nextinsightsecondcardindividualstyle, nextinsightsecondcardindividualstyledisable, nextinsightsecondextracardstyle } from "../StylesFolderForNextInsight/_NextInsightPageStyleConstant";

interface NextInsightPageProps {
    setSelectedBoxName: (name: string) => void;
    setSelectedImageBoxUrl: (url: string) => void;
    setSelectedVideoBoxUrl: (url: string) => void;
    setSelectedAudioBoxUrl: (url: string) => void;
    setmodalopenfiletype: (fileType: string) => void;
    modalopenfiletype: any;
}

export const Toggler: FC<NextInsightPageProps> = ({setSelectedAudioBoxUrl,setSelectedBoxName,setSelectedImageBoxUrl,setSelectedVideoBoxUrl,setmodalopenfiletype,modalopenfiletype}) => {
  const { cardsData,cardsImageData,cardsAudioData,cardsVideoData,searchType } = useSelector((state: any) => state.semanticSearchReducer);

  const handleFileTypeChange = (newFileType: string) => {
    if (newFileType === "Document" && cardsData.length > 0) {
      setSelectedBoxName(cardsData[0].filename);
      setmodalopenfiletype(newFileType);
    } else if (newFileType === "Image" && cardsImageData.length > 0) {
      setSelectedImageBoxUrl(cardsImageData[0].url);
      setmodalopenfiletype(newFileType);
    } else if (newFileType === "Video" && cardsVideoData.length > 0) {
      setSelectedVideoBoxUrl(cardsVideoData[0].s3_url);
      setmodalopenfiletype(newFileType);
    } else if (newFileType === "Audio" && cardsAudioData.length > 0) {
      setSelectedAudioBoxUrl(cardsAudioData[0].s3_url);
      setmodalopenfiletype(newFileType);
    }
  };

  return (
    <>
     <div style={{ display: "flex", gap: "4px", marginLeft: "15px" }}>
            <div
              onClick={() => cardsData?.length !== 0 && handleFileTypeChange("Document")}
              style={{
                background: modalopenfiletype === "Document" ? "#1E4DA1" : "white",
                color: modalopenfiletype === "Document" ? "white" : "#1E4DA1",
                padding: "10px",
                fontSize: "12px",
                borderRadius: "4px 4px 0px 0px",
                cursor: cardsData?.length === 0 ? "not-allowed" : "pointer",
              }}
            >
              {searchType==="Web Search"?`Web URL(s) | 0${cardsData?.length}`:`Document(s) | 0${cardsData?.length}`}
            </div>

            <div
              onClick={() => cardsImageData?.length !== 0 && handleFileTypeChange("Image")}
              style={{
                background: modalopenfiletype === "Image" ? "#1E4DA1" : "white",
                color: modalopenfiletype === "Image" ? "white" : "#1E4DA1",
                padding: "10px",
                fontSize: "12px",
                borderRadius: "4px 4px 0px 0px",
                cursor: cardsImageData?.length === 0 ? "not-allowed" : "pointer",
              }}
            >
              Image(s) | 0{cardsImageData?.length}
            </div>

            <div
              onClick={() => cardsAudioData?.length !== 0 && handleFileTypeChange("Audio")}
              style={{
                background: modalopenfiletype === "Audio" ? "#1E4DA1" : "white",
                color: modalopenfiletype === "Audio" ? "white" : "#1E4DA1",
                padding: "10px",
                fontSize: "12px",
                borderRadius: "4px 4px 0px 0px",
                cursor: cardsAudioData?.length === 0 ? "not-allowed" : "pointer",
              }}
            >
              Audio(s) | 0{cardsAudioData?.length}
            </div>

            <div
              onClick={() => cardsVideoData?.length !== 0 && handleFileTypeChange("Video")}
              style={{
                background: modalopenfiletype === "Video" ? "#1E4DA1" : "white",
                color: modalopenfiletype === "Video" ? "white" : "#1E4DA1",
                padding: "10px",
                fontSize: "12px",
                borderRadius: "4px 4px 0px 0px",
                cursor: cardsVideoData?.length === 0 ? "not-allowed" : "pointer",
              }}
            >
              Video(s) | 0{cardsVideoData?.length}
            </div>
          </div>
    </>
  );
};
