import { useEffect, useState } from "react";
import { ContentNewtonState } from "../../../../Store/reducers/_contentNewtonReducer";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { getFinalAssetURL, sseGetFinalAsset } from "../../../../Store/requests/_contentNewtonRequests";
import { FinalAssetSSEResponse } from "../Module Selection/types";
import { SSEMessage, SSEMessageList } from "../../components/common/DisplaySSEMessages";
import { setCNCurrentIndexDoneNextIndexProcess, setCNFinalAsset } from "../../../../Store/actions/_contentNewtonActions";
import { Button, notification, Skeleton, Tooltip, Col, Row } from "antd";
import { ConversationStepsMapping, ConversationStepsName } from "../../resources/constants";
import { DownloadOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { FinalAssetPreviewModal } from "./FinalAssetPreviewModal";
import { FinalAsset as assestInfoType } from "../../resources/types";

export const FinalAsset = () => {
  const { currentStep, finalAsset, orderId, selectedTemplateId }: ContentNewtonState = useAppSelector((state) => state.contentNewtonReducer);

  const [loading, setLoading] = useState(finalAsset.length === 0);
  const [sseMessages, setSSEMessages] = useState<SSEMessage[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [visible, setVisible] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<assestInfoType | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (finalAsset && finalAsset.length > 0) {
      return;
    }

    const sse = sseGetFinalAsset(orderId, selectedTemplateId);

    sse.addEventListener("pending", async (data: { data: string }) => {
      console.log(`pending`, data?.data);
      setLoading(false);

      let parsedData: FinalAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.message) {
          setSSEMessages((prev) => [...prev, parsedData.message]);
        }
      }
    });

    sse.addEventListener("end", async (data: { data: string }) => {
      console.log(`sse.addEventListener("end"`, data?.data);
      sse.close();
      setLoading(false);

      let parsedData: FinalAssetSSEResponse;
      try {
        parsedData = JSON.parse(data.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        sse.close();
        return;
      }

      if (parsedData) {
        if (parsedData.error) {
          setErrorMessage(parsedData.message.text);
        } else if (parsedData.data) {
          setSSEMessages((prev) => [...prev, { type: "main" }]); //Blank message to finish the last step
          dispatch(setCNFinalAsset(parsedData.data));
        }
      }
    });

    sse.onerror = (err) => {
      notification.error({ message: `SSE Templates Listing Error!\n ${err}` });
      sse.close();
      setLoading(false);
    };
  }, [finalAsset]);

  const isFinalAssetStep = ConversationStepsMapping[currentStep] === ConversationStepsName.CREATING_FINAL_ASSET;

  const handleEdit = (assetInfo: assestInfoType) => {
    setEditMode(true);
    setOpenPreviewModal(true);
    setSelectedAsset(assetInfo);
  };

  //   const handleDownload = async () => {
  //     if (!finalAsset) return;
  //     const res = await getFinalAssetURL(finalAsset.asset_id);

  //     if (res?.data?.url) {
  //       const element = document.createElement("a");
  //       element.href = res.data.url;
  //       document.body.appendChild(element);
  //       element.click();
  //       document.body.removeChild(element);
  //     }
  //   };
  const handleDownload = async (html?: string) => {
    if (!html) return;
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(html));
    element.setAttribute("download", "FinalAsset.html");
    element.click();
  };

  const handlePreview = (assetInfo: assestInfoType) => {
    setEditMode(false);
    setOpenPreviewModal(true);
    setSelectedAsset(assetInfo);
  };

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : errorMessage ? (
        <div>{errorMessage}</div>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "5px" }}>
          <SSEMessageList sseMessages={sseMessages} />
          <Row
            gutter={[8, 8]}
            style={{
              display: "flex",
              overflowX: "auto",
              padding: "5px",
              maxWidth: "100%", // Ensure it doesn't exceed the parent width
              WebkitOverflowScrolling: "touch" // Smooth scrolling on iOS
            }}
          >
            {finalAsset.length > 0 &&
              finalAsset.map((assetInfo: assestInfoType, index: number) => {
                return (
                  <Col span={6} key={index}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                      <div
                        style={{
                          // width: "300px",
                          height: "300px",
                          backgroundColor: "#f0f7ff", //"white",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          border: "1px solid lightgray",
                          borderRadius: "10px",
                          overflow: "hidden"
                        }}
                        onMouseEnter={() => setVisible(true)}
                        onMouseLeave={() => setVisible(false)}
                      >
                        <img src={assetInfo?.thumbnail} style={{ width: "100%" }} />
                        <div
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            display: "flex",
                            gap: "5px",
                            visibility: visible ? "visible" : "hidden"
                          }}
                        >
                          <Tooltip title="Edit Asset">
                            <EditOutlined
                              style={{
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                                padding: "3px",
                                cursor: "pointer"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(assetInfo);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Download">
                            <DownloadOutlined
                              style={{
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                                padding: "3px",
                                cursor: "pointer"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownload(assetInfo?.html);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Preview">
                            <EyeOutlined
                              style={{
                                fontSize: "18px",
                                color: "#fff",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                borderRadius: "50%",
                                padding: "3px",
                                cursor: "pointer"
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePreview(assetInfo);
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>

          <div
            style={{
              display: isFinalAssetStep && finalAsset.length ? "flex" : "none",
              justifyContent: "flex-end",
              marginTop: "5px"
            }}
          >
            <Button type="primary" onClick={() => dispatch(setCNCurrentIndexDoneNextIndexProcess())}>
              Proceed
            </Button>
          </div>
          {finalAsset.length > 0 && selectedAsset && (
            <FinalAssetPreviewModal open={openPreviewModal} setOpen={setOpenPreviewModal} asset={selectedAsset} edit={editMode} />
          )}
        </div>
      )}
    </div>
  );
};
