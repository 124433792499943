import React from "react";
import { Card, Checkbox } from "antd";
import "./CardWithOverlayCheckbox.css";
import { style } from "../../../../../Content Transcreation/Content Translation/components/canvas/constans";

interface CardWithOverlayCheckboxProps<T> {
  item: T;
  isSelected: boolean;
  isPreviewed: boolean;
  onCheck: () => void;
  onClick: () => void;
  checkboxPosition?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  borderOn: "selected" | "previewed";
  opacityOn: "selected" | "previewed";
  content?: React.ReactNode;
}

const CardWithOverlayCheckbox = <T extends object>({
  item,
  isSelected,
  isPreviewed,
  onCheck,
  onClick,
  checkboxPosition = "top-right",
  borderOn,
  opacityOn,
  content
}: CardWithOverlayCheckboxProps<T>) => {
  const borderColor = borderOn === "selected" && isSelected ? "blue" : borderOn === "previewed" && isPreviewed ? "green" : "transparent";

  const opacity = opacityOn === "selected" && isSelected ? 0.8 : opacityOn === "previewed" && isPreviewed ? 0.6 : 1;

  return (
    <div
      className={`card-with-overlay-checkbox card-container ${checkboxPosition}`}
      style={{
        // border: `2px solid ${borderColor}`,
        // opacity,
        position: "relative",
        borderRadius: "8px",
        background: "#f0f7ff",
        border: isPreviewed ? "3px solid #008fb4" : "3px solid transparent",
        boxShadow: isPreviewed ? "none" : "0 0 0 1px #0000001a, 0 0 0 2px transparent",
        opacity: isPreviewed ? 0.7 : 1,
        userSelect: "none",
        cursor: "pointer",
        overflow: "hidden"
      }}
      onClick={onClick}
    >
      <Checkbox checked={isSelected} onChange={onCheck} className="overlay-checkbox" onClick={(e) => e.stopPropagation()} />
      <Card
        styles={{
          body: { padding: 0, height: "100%", display: "flex", justifyContent: "center", alignItems: "center", background: "#f0f7ff" }
        }}
        style={{ height: "100%", border: "none" }}
      >
        {content}
      </Card>
    </div>
  );
};

export default CardWithOverlayCheckbox;
