import { useState } from "react";

type CardItem<T> = T & { id: string | number };

export const useSelectablePreviewableCards = <T extends object>(options?: {
  multiSelect?: boolean;
  initialCheckedItems?: CardItem<T>[];
  initialPreviewedItem?: CardItem<T>;
}) => {
  const { multiSelect = true, initialCheckedItems = [], initialPreviewedItem = null } = options ?? {};

  const [checkedItems, setCheckedItems] = useState<CardItem<T>[]>(initialCheckedItems);
  const [previewedItem, setPreviewedItem] = useState<CardItem<T> | null>(initialPreviewedItem);

  const toggleCheckedItem = (item: CardItem<T>) => {
    multiSelect
      ? setCheckedItems((prevItems) =>
          prevItems.some((checked) => checked.id === item.id) ? prevItems.filter((checked) => checked.id !== item.id) : [...prevItems, item]
        )
      : setCheckedItems([item]);
  };

  const selectPreviewItem = (item: CardItem<T>) => {
    setPreviewedItem(item);
  };

  return {
    checkedItems,
    previewedItem,
    toggleCheckedItem,
    selectPreviewItem
  };
};
